import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      // 首页
      {
        path: "/",
        name: "SY",
        component: () => import("../views/gongneng/Shouye.vue"),
      },
      // 超市模拟器
      {
        path: "/Csmnq",
        name: "Csmnq",
        component: () => import("../views/dome/Csmnq.vue"),
        meta: {
          title: "Supermarket simulator",
        },
      },
      // 农夫保卫战
      {
        path: "/Nfbwz",
        name: "Nfbwz",
        component: () => import("../views/dome/Nfbwz.vue"),
        meta: {
          title: "Peasant defense",
        },
      },
      // 我爱连连消
      {
        path: "/Wallx",
        name: "Wallx",
        component: () => import("../views/dome/Wallx.vue"),
        meta: {
          title: "Dice Link",
        },
      },
      // 别墅修复日记
      {
        path: "/Bsxfrj",
        name: "Bsxfrj",
        component: () => import("../views/dome/Bsxfrj.vue"),
        meta: {
          title: "Mansion Diary",
        },
      },
      // 变异恐龙
      {
        path: "/Bykl",
        name: "Bykl",
        component: () => import("../views/dome/Bykl.vue"),
        meta: {
          title: "Mutant dinosaur",
        },
      },
      // 单挑足球
      {
        path: "/Dtzq",
        name: "Dtzq",
        component: () => import("../views/dome/Dtzq.vue"),
        meta: {
          title: "Singles football",
        },
      },
      // 宿舍大逃杀
      {
        path: "/Ssdts",
        name: "Ssdts",
        component: () => import("../views/dome/Ssdts.vue"),
        meta: {
          title: "Keep Zombie Away",
        },
      },
      // 坑爹大冒险
      {
        path: "/Kddmx",
        name: "Kddmx",
        component: () => import("../views/dome/Kddmx.vue"),
        meta: {
          title: "Awful Adventure",
        },
      },
      // 猛鬼别敲门
      {
        path: "/Mgbqm",
        name: "Mgbqm",
        component: () => import("../views/dome/Mgbqm.vue"),
        meta: {
          title: "Fierce ghost, don't knock at the door",
        },
      },
      // 房东模拟器
      {
        path: "/Fdmnq",
        name: "Fdmnq",
        component: () => import("../views/dome/Fdmnq.vue"),
        meta: {
          title: "Landlord Growth Diary",
        },
      },
      // 水果蛇成长日记
      {
        path: "/Sgsczrj",
        name: "Sgsczrj",
        component: () => import("../views/dome/Sgsczrj.vue"),
        meta: {
          title: "Fruit Snake",
        },
      },
      // 建造帝国
      {
        path: "/Jzdg",
        name: "Jzdg",
        component: () => import("../views/dome/Jzdg.vue"),
        meta: {
          title: "Purchase Kingdom",
        },
      },
      // 地下城割草
      {
        path: "/Dxcgc",
        name: "Dxcgc",
        component: () => import("../views/dome/Dxcgc.vue"),
        meta: {
          title: "Survivors at War",
        },
      },
      // 疯狂炮兵
      {
        path: "/Fkpb",
        name: "Fkpb",
        component: () => import("../views/dome/Fkpb.vue"),
        meta: {
          title: "Crazy artillery",
        },
      },
      // 吞噬八爪鱼
      {
        path: "/Tsbzy",
        name: "Tsbzy",
        component: () => import("../views/dome/Tsbzy.vue"),
        meta: {
          title: "Devour Octopus",
        },
      },
      // 竞速都市
      {
        path: "/Jsds",
        name: "Jsds",
        component: () => import("../views/dome/Jsds.vue"),
        meta: {
          title: "Racing City",
        },
      },
      // 保护狗狗
      {
        path: "/Bhgg",
        name: "Bhgg",
        component: () => import("../views/dome/Bhgg.vue"),
        meta: {
          title: "Protect the Doggy",
        },
      },
      // 花朵爆爆乐
      {
        path: "/Hdbbl",
        name: "Hdbbl",
        component: () => import("../views/dome/Hdbbl.vue"),
        meta: {
          title: "Flower explosion music",
        },
      },
      // 积木枪神
      {
        path: "/Jmqs",
        name: "Jmqs",
        component: () => import("../views/dome/Jmqs.vue"),
        meta: {
          title: "Toy Block Gunner",
        },
      },
      // 动物大亨
      {
        path: "/Dwdh",
        name: "Dwdh",
        component: () => import("../views/dome/Dwdh.vue"),
        meta: {
          title: "Zootopia",
        },
      },
      // 樱花校园
      {
        path: "/Yhxy",
        name: "Yhxy",
        component: () => import("../views/dome/Yhxy.vue"),
        meta: {
          title: "Sakura Campus",
        },
      },
      // 鱿鱼挑战赛
      {
        path: "/Yytzs",
        name: "Yytzs",
        component: () => import("../views/dome/Yytzs.vue"),
        meta: {
          title: "Squid challenge",
        },
      },
      // 公鸡冲冲冲
      {
        path: "/Gjccc",
        name: "Gjccc",
        component: () => import("../views/dome/Gjccc.vue"),
        meta: {
          title: "Rooster, rush, rush",
        },
      },
      // 欢乐养殖场
      {
        path: "/Hlyzc",
        name: "Hlyzc",
        component: () => import("../views/dome/Hlyzc.vue"),
        meta: {
          title: "Happy farm",
        },
      },
      // 木筏海岛生存
      {
        path: "/Mfhdsc",
        name: "Mfhdsc",
        component: () => import("../views/dome/Mfhdsc.vue"),
        meta: {
          title: "Raft Island survival",
        },
      },
      // 花裤衩大冒险
      {
        path: "/Hkcdmx",
        name: "Hkcdmx",
        component: () => import("../views/dome/Hkcdmx.vue"),
        meta: {
          title: "Flower Pants Adventure",
        },
      },
      // 旷野求生
      {
        path: "/Kyqs",
        name: "Kyqs",
        component: () => import("../views/dome/Kyqs.vue"),
        meta: {
          title: "Survive in the wilderness",
        },
      },
      // 我不要躺平
      {
        path: "/Wbytp",
        name: "Wbytp",
        component: () => import("../views/dome/Wbytp.vue"),
        meta: {
          title: "I don't want to lie flat",
        },
      },
      // 海洋进化世界
      {
        path: "/Hyjhsj",
        name: "Hyjhsj",
        component: () => import("../views/dome/Hyjhsj.vue"),
        meta: {
          title: "Marine evolution world",
        },
      },
      // 机甲兽争霸
      {
        path: "/Jjszb",
        name: "Jjszb",
        component: () => import("../views/dome/Jjszb.vue"),
        meta: {
          title: "Mecha beast battle",
        },
      },
      // 我的小宇宙
      {
        path: "/Wdxyz",
        name: "Wdxyz",
        component: () => import("../views/dome/Wdxyz.vue"),
        meta: {
          title: "My little universe",
        },
      },
      // 完美披萨
      {
        path: "/Wmps",
        name: "Wmps",
        component: () => import("../views/dome/Wmps.vue"),
        meta: {
          title: "Perfect pizza",
        },
      },
      // 画个兵团
      {
        path: "/Hgbt",
        name: "Hgbt",
        component: () => import("../views/dome/Hgbt.vue"),
        meta: {
          title: "Draw a corps",
        },
      },
      // 坦克向前冲
      {
        path: "/Tkxqc",
        name: "Tkxqc",
        component: () => import("../views/dome/Tkxqc.vue"),
        meta: {
          title: "The tank charged forward",
        },
      },
      // 黎明将至
      {
        path: "/Lmjz",
        name: "Lmjz",
        component: () => import("../views/dome/Lmjz.vue"),
        meta: {
          title: "Dawn is coming",
        },
      },
      // 画线火柴人
      {
        path: "/Hxhcr",
        name: "Hxhcr",
        component: () => import("../views/dome/Hxhcr.vue"),
        meta: {
          title: "Matchmaker",
        },
      },
      // 纸牌塔
      {
        path: "/Zpt",
        name: "Zpt",
        component: () => import("../views/dome/Zpt.vue"),
        meta: {
          title: "Card tower",
        },
      },
      // 分类
      {
        path: "/Fl",
        name: "Fl",
        component: () => import("../views/dome/FenLei.vue"),
        children: [],
      },
      // 分类子页面
      {
        path: "/FLZ",
        name: "FLZ",
        component: () => import("../views/dome/FenleiZhi.vue"),
      },
      // 搜索页面
      {
        path: "/SS",
        name: "SS",
        component: () => import("../views/gongneng/Sousuo.vue"),
      },
      // 收纳达人
      {
        path: "/Sndr",
        name: "Sndr",
        component: () => import("../views/dome/Sndr.vue"),
      },
      // 菜狗大战
      {
        path: "/Cgdz",
        name: "Cgdz",
        component: () => import("../views/dome/Cgdz.vue"),
      },
      // 找不同
      {
        path: "/Zbt",
        name: "Zbt",
        component: () => import("../views/dome/Zbt.vue"),
      },
      // 领地霸主
      {
        path: "/Ldbz",
        name: "Ldbz",
        component: () => import("../views/dome/Ldbz.vue"),
      },
      // 羊了个羊
      {
        path: "/Ylgy",
        name: "Ylgy",
        component: () => import("../views/dome/Ylgy.vue"),
      },
      // 隐私政策
      {
        path: "/Yszc",
        name: "Yszc",
        component: () => import("../views/gongneng/Yszc.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
  <div id="app">
    <nav>
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    </nav>
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.oferW();
    // location.reload()
  },
  methods: {
    oferW() {
      // 获取到当前窗口的宽度与高度
      var html_ = document.documentElement || document.body;
      var clientWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
      html_.style.fontSize = clientWidth / 20 + "px";
      console.log(html_.style.fontSize, "body的字体大小");
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}
</style>
